import { useEffect, useRef } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { ProjectState } from '~/src/Project/store';
import useFindOne from '~/src/Projects/hooks/useFindOne';
import {
  entryProjectSelector,
  isNeedProjectUpdateSelector,
} from '~/src/Projects/store';

const useFindOneOnUpdate = (projectId: number) => {
  const [findOne] = useFindOne(projectId);
  const project = useRecoilValue(entryProjectSelector(projectId));
  const [needUpdate, setNeedProjectUpdate] = useRecoilState(
    isNeedProjectUpdateSelector(projectId),
  );
  const previousProjectState = useRef(null);

  const runUpdate = async () => {
    try {
      await findOne();
    } catch {
      /* empty */
    }
    setNeedProjectUpdate(false);
  };

  useEffect(() => {
    if (
      project
      && project.state === ProjectState.UploadingTrainingFile
      && JSON.stringify(previousProjectState.current)
        !== JSON.stringify(project?.state)
    ) {
      runUpdate();
      previousProjectState.current = project?.state;
    }
  }, [project?.state]);

  useEffect(() => {
    if (needUpdate) {
      runUpdate();
    }
  }, [needUpdate]);
};

export default useFindOneOnUpdate;
